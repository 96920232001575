<template>
  <svg
    class="v-icon__svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 450 421.43"
  >
    <path
      d="M356.81,83.14A148.58,148.58,0,0,0,251,39.28c-82.44,0-149.53,67.08-149.57,149.52a149.3,149.3,0,0,0,20,74.75l-20.83,54.86a17.16,17.16,0,0,0,21.82,22.25l57.08-20.42A149.72,149.72,0,0,0,251,338.44H251c82.43,0,149.55-67.08,149.57-149.52A148.56,148.56,0,0,0,356.81,83.14ZM251,309h0a119.84,119.84,0,0,1-61.15-16.74l-4.39-2.61-45.48,11.93,12.13-44.33-2.85-4.54A120.17,120.17,0,1,1,251,309Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M179.3,197.51h0a14.73,14.73,0,0,0-14,19.17,90.29,90.29,0,0,0,59.22,59.23,14.74,14.74,0,0,0,19.18-14h0a14.77,14.77,0,0,0-10.44-14.06,60.93,60.93,0,0,1-39.88-39.89A14.76,14.76,0,0,0,179.3,197.51Zm0-15.34h0a14.77,14.77,0,0,0,14.07-10.44,60.91,60.91,0,0,1,39.88-39.88,14.77,14.77,0,0,0,10.44-14.06h0a14.74,14.74,0,0,0-19.18-14A90.29,90.29,0,0,0,165.29,163,14.73,14.73,0,0,0,179.3,182.17Zm144.11,0h0a14.74,14.74,0,0,0,14-19.17,90.33,90.33,0,0,0-59.23-59.23,14.73,14.73,0,0,0-19.17,14h0a14.76,14.76,0,0,0,10.43,14.06,60.93,60.93,0,0,1,39.89,39.88A14.76,14.76,0,0,0,323.41,182.17ZM259,261.89h0a14.73,14.73,0,0,0,19.17,14,90.33,90.33,0,0,0,59.23-59.23,14.74,14.74,0,0,0-14-19.17h0a14.75,14.75,0,0,0-14.06,10.43,60.94,60.94,0,0,1-39.89,39.89A14.76,14.76,0,0,0,259,261.89Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M35.18,393q8.87-9,22.54-9,18.3,0,26.76,12.14a26.41,26.41,0,0,1,5,13.67H74.18a20.74,20.74,0,0,0-3.75-8q-4.09-4.79-12.13-4.78a15,15,0,0,0-12.91,6.74q-4.72,6.74-4.73,19.07t5,18.48a15.63,15.63,0,0,0,12.67,6.14q7.89,0,12-5.27a23.15,23.15,0,0,0,3.79-8.51H89.35a33.06,33.06,0,0,1-10.06,19.5q-8.1,7.5-20.74,7.51-15.64,0-24.6-10.15t-9-27.95Q25,403.43,35.18,393Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M139.54,404.77a15.86,15.86,0,0,1,6.71,5.33,14.11,14.11,0,0,1,2.73,6.24,77.64,77.64,0,0,1,.49,10.48v31.86H135v-33a14.59,14.59,0,0,0-1.48-7.07q-1.92-3.78-7.31-3.78c-3.71,0-6.54,1.25-8.46,3.75s-2.89,6.09-2.89,10.73v29.38H100.69V385.62h14.17v25.86a17.41,17.41,0,0,1,7.11-6.59,20,20,0,0,1,8.49-1.86A22.93,22.93,0,0,1,139.54,404.77Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M184.27,425.71a22.2,22.2,0,0,0,5.66-1.25q3.06-1.29,3.06-4c0-2.22-.79-3.75-2.35-4.6a14.9,14.9,0,0,0-6.88-1.26c-3.39,0-5.8.83-7.21,2.48a10.22,10.22,0,0,0-2,5H160.86a20.72,20.72,0,0,1,4-11.69q5.64-7.15,19.37-7.16a34.64,34.64,0,0,1,15.89,3.53q6.94,3.53,6.94,13.33V444.9c0,1.73,0,3.81.1,6.27A8,8,0,0,0,208,455a5.13,5.13,0,0,0,2.09,1.64v2.09H194.68a15.5,15.5,0,0,1-.9-3.09c-.16-1-.3-2-.4-3.28a29,29,0,0,1-6.8,5.42,20.54,20.54,0,0,1-10.4,2.64,17.94,17.94,0,0,1-12.23-4.2q-4.83-4.21-4.83-11.92,0-10,7.76-14.47,4.26-2.43,12.53-3.48Zm8.67,6.61a16.38,16.38,0,0,1-2.75,1.37,23.51,23.51,0,0,1-3.81,1l-3.22.59a21.27,21.27,0,0,0-6.52,1.94,6.49,6.49,0,0,0-3.35,6q0,3.63,2.05,5.25a7.8,7.8,0,0,0,5,1.61,14.81,14.81,0,0,0,8.56-2.68q3.91-2.68,4.07-9.8Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M263.15,407.58q5.33,4.4,5.32,14.6v36.5H254v-33a15.15,15.15,0,0,0-1.13-6.57q-2.07-4.17-7.9-4.18-7.15,0-9.82,6.12a21,21,0,0,0-1.39,8.26v29.34H219.54V404.57h13.73v7.91a23.85,23.85,0,0,1,5.15-6,17.81,17.81,0,0,1,11-3.28Q257.83,403.18,263.15,407.58Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M325.36,407.58q5.33,4.4,5.33,14.6v36.5H316.16v-33a15.15,15.15,0,0,0-1.13-6.57Q313,415,307.13,415q-7.15,0-9.82,6.12a21.2,21.2,0,0,0-1.39,8.26v29.34H281.75V404.57h13.73v7.91a23.85,23.85,0,0,1,5.15-6,17.81,17.81,0,0,1,11-3.28Q320,403.18,325.36,407.58Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M379.19,405.57a22,22,0,0,1,9.36,8,26.63,26.63,0,0,1,4.32,11.2,69.42,69.42,0,0,1,.47,10.76H353.86q.33,8.16,5.67,11.44a14.38,14.38,0,0,0,7.81,2,12,12,0,0,0,7.87-2.49,11.83,11.83,0,0,0,2.92-3.73H392.6q-.57,4.83-5.26,9.8-7.27,7.9-20.39,7.91a29.57,29.57,0,0,1-19.09-6.67q-8.28-6.68-8.27-21.7,0-14.08,7.46-21.6T366.44,403A30.91,30.91,0,0,1,379.19,405.57ZM358,417.81a14.73,14.73,0,0,0-3.77,8.39h24.42q-.39-5.64-3.78-8.56a12.41,12.41,0,0,0-8.41-2.92Q361,414.72,358,417.81Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M415,458.68H400.8v-73.3H415Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
    <path
      d="M439.79,441.37q.45,3.78,1.95,5.37,2.65,2.83,9.79,2.84a14.93,14.93,0,0,0,6.67-1.25,4,4,0,0,0,2.48-3.73,4,4,0,0,0-2-3.63q-2-1.25-14.83-4.27-9.24-2.29-13-5.72t-3.78-9.75a16.86,16.86,0,0,1,5.91-12.9q5.89-5.4,16.62-5.4,10.16,0,16.57,4.05t7.35,14H459.34a8.37,8.37,0,0,0-1.55-4.32q-2.34-2.9-8-2.89-4.63,0-6.61,1.44a4.16,4.16,0,0,0-2,3.38,3.71,3.71,0,0,0,2.1,3.54q2.09,1.14,14.8,3.92,8.46,2,12.69,6a13.66,13.66,0,0,1,4.18,10.2,16.44,16.44,0,0,1-6,13.15q-6,5.1-18.56,5.1-12.8,0-18.91-5.4a17.54,17.54,0,0,1-6.1-13.75Z"
      transform="translate(-25 -39.28)"
      fill="currentColor"
    />
  </svg>
</template>
