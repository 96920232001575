<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000"
    xml:space="preserve"
  >
    <g fill="currentColor">
      <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
        <path
          d="M9226.5,4781.6c-35.3-22.1-2099.9-2077.9-4586.3-4564.2C1352.3-3075,115.7-4324.8,104.7-4371.2c-30.9-128.1,97.2-256.1,225.2-225.2c46.4,11,1298.4,1249.8,4597.4,4546.6c2493,2493,4548.8,4559.8,4566.4,4592.9c44.2,81.7,22.1,178.9-50.8,236.3C9372.2,4834.6,9312.6,4834.6,9226.5,4781.6z"
        />
        <path
          d="M581.6,4419.5c-28.7-15.5-66.2-53-81.7-81.7c-35.3-70.7-41.9-6851.9-6.6-6944.6c35.3-90.5,141.3-121.5,426.2-121.5c287.1,0,315.8,15.5,366.6,198.7c92.7,324.6,351.1,635.9,660.2,790.5c101.6,50.8,216.4,97.2,256.1,103.8c53,11,128.1,70.7,280.4,223l207.6,207.5h-923H846.6l4.4,2634.3l6.6,2632.1l2634.3,6.6l2632.1,4.4V3152v-923l342.3,342.3l342.3,342.3l-147.9,11l-150.1,11l-6.6,700l-4.4,697.8l-68.4,57.4l-68.5,57.4H3500.8C1248.5,4448.2,625.8,4441.5,581.6,4419.5z"
        />
        <path
          d="M7760.3,2253.3l-139.1-139.1L8153.4,1052C8480.2,398.4,8703.2-21.1,8734.1-43.2c39.7-28.7,117-35.3,419.5-35.3h371v-563.1v-563.1H8012H6499.5v1097.5V992.4l-187.7-187.7L6124.1,617v-909.8v-912h-912h-909.8l-393-393L3514-1993l119.2-174.5c66.2-97.1,136.9-234.1,159-306.9c22.1-72.9,46.4-156.8,55.2-187.7l17.7-55.2h1318.2h1320.5l64,196.5c185.5,569.7,669.1,925.2,1256.4,925.2c598.4,0,1088.6-373.2,1269.7-967.2l48.6-154.6l278.2-6.6c315.8-6.6,419.6,19.9,457.1,117c13.2,35.3,22.1,569.7,22.1,1415.4V168.8l-64.1,64l-64,64H9359h-410.7l-516.7,1048.9c-282.6,576.3-518.9,1048.9-523.3,1048.9C7903.8,2394.6,7837.6,2330.6,7760.3,2253.3z"
        />
        <path
          d="M7663.2-1977.5c-494.6-86.1-852.4-587.4-768.4-1073.1c90.5-516.7,578.5-870,1077.6-781.7c401.9,68.4,704.4,373.2,781.7,781.7c44.2,240.7-41.9,547.6-212,755.2C8347.7-2057.1,7974.5-1922.3,7663.2-1977.5z"
        />
        <path
          d="M2573.4-2933.7l-669.1-669.1l64-46.4c117-86.1,284.9-159,432.8-183.3c616.1-108.2,1181.4,457.1,1075.4,1073.2c-26.5,141.3-112.6,342.3-194.3,446l-39.7,48.6L2573.4-2933.7z"
        />
      </g>
    </g>
  </svg>
</template>
